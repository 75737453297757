<script lang="ts">
  import { css } from '$utils/utils';
  import Button from '$components/style/button/button.svelte';

  /** Scroll-container element ref */
  export let scrollList;
  export let scrollPosition = 0;
  export let id;
  export let gridArea;
  export let firstButtonWidth = 0;
  export let lastButtonWidth = 0;

  const scrollContainerStyle = css({
    gridArea: gridArea,
    height: '100%',
    padding: '$x2',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: '"left right"',
    alignItems: 'center',
    pointerEvents: 'none',

    '& > :where(button-left, button-right)': {
      gridArea: 'right',
      translate: '1.5rem 0',
      justifySelf: 'end',
      pointerEvents: 'auto',
      filter: 'drop-shadow(0 1px 16px rgba(0, 0, 0, 0.15))',
    },

    '& > button-left': {
      gridArea: 'left',
      translate: '-1.5rem 0',
      justifySelf: 'start',
    },

    '.mozart-button': {
      height: '32px',
      width: '32px',
    },
  });

  let isHolding: string | number | NodeJS.Timeout | null | undefined = null;

  const handleClickLeft = (_: CustomEvent | null, amount = 200) => {
    console.log('Clicking left arrow');
    scrollList.scrollBy(-amount, 0);
    if (scrollList.scrollLeft <= amount) scrollList.scrollLeft = 0;
  };

  const handleClickRight = (_: CustomEvent | null, amount = 200) => {
    console.log('Clicking right arrow');
    const distanceToEnd =
      scrollList?.scrollWidth - scrollList?.clientWidth - scrollPosition;
    // the filter snaps to x start proximity. Checking distance < amount * 2 would make it skip one button too many
    const shouldScrollToEnd = distanceToEnd <= amount + lastButtonWidth;

    if (shouldScrollToEnd) {
      scrollList.scrollBy(distanceToEnd, 0);
    } else {
      scrollList.scrollBy(amount, 0);
    }
    if (
      scrollList.scrollLeft + amount >=
      scrollList.scrollWidth - scrollList.clientWidth
    )
      scrollList.scrollLeft = scrollList.scrollWidth;
  };

  const handleMouseDown = (e: { detail: { id: string | string[] } }) => {
    const left = e.detail?.id.includes('left');

    isHolding = setTimeout(() => {
      if (left) handleClickLeft(null, 10000);
      else handleClickRight(null, 10000);
    }, 1000);
  };

  $: isScrollMax =
    Math.ceil(scrollPosition) >=
    scrollList?.scrollWidth - scrollList?.clientWidth;

  $: isScrollMin = scrollPosition < firstButtonWidth;
</script>

<!-- <svelte:window
  on:mouseup={() => {
    if (isHolding) {
      console.log("Clearing holding timeout?")
      clearTimeout(isHolding);
    }
  }}
/> -->

<scroll-button-container class={scrollContainerStyle()} {id}>
  {#if !isScrollMin}
    <button-left>
      <Button
        type="round"
        size="none"
        icon="arrow-left"
        clickLabel="filter-feed"
        clickValue="{id}-left"
        id="{id}-left"
        on:click={handleClickLeft}
        on:mousedown={handleMouseDown}
      />
    </button-left>
  {/if}
  {#if !isScrollMax}
    <button-right>
      <Button
        type="round"
        size="none"
        icon="arrow-right"
        clickLabel="filter-feed"
        clickValue="{id}-right"
        id="{id}-right"
        on:click={handleClickRight}
        on:mousedown={handleMouseDown}
      />
    </button-right>
  {/if}
</scroll-button-container>
