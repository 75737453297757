<script lang="ts">
  import { readable } from 'svelte/store';
  import { onMount } from 'svelte';

  import { createTeaserStore, fetchFrontFeedList } from '$stores/teaser-store';
  import Button from '$components/style/button/button.svelte';
  import { Locations } from '$stores/router-store';
  import Icon from '$components/style/icon/icon.svelte';
  import { dirtySelectedSites, type UserStore } from '$stores/user-store';
  import Loading from '$components/style/loading/loading.svelte';
  import i18n from '$i18n/i18n';
  import { removeSoftHyphen, css } from '$utils/utils';

  export let location: Locations;
  export let userData: Exclude<UserStore['data'], null>;

  const { uuid, trackingKey } = userData;

  const widgetWrapperStyle = css({
    backgroundColor: 'rgba(0 ,0 ,0 , .1)',
    borderRadius: '$baseXl',
    padding: '$x4',
  });

  const headlineStyle = css({
    fontstyle: 'baseHeadlineM',
    margin: 0,
    color: '$baseFg',
  });

  const headerWrapperStyle = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '$x4',
    margin: '0 0 $x3',
    width: '100%',
  });

  const teaserListStyle = css({
    backgroundColor: 'rgba(255, 255, 255, .75)',
    borderRadius: '10px',
    padding: '$x4',
    margin: '0',
    listStyleType: 'none',
  });

  const teaserItemStyle = css({
    marginBottom: '$x4',
  });

  const topWrapperStyle = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '$x2',
  });

  const publisherStyle = css({
    display: 'flex',
    alignItems: 'center',
  });

  const linkStyle = css({
    textDecoration: 'none',
    color: '#000',
    display: 'block',

    '&:hover, &:focus-visible': {
      textDecoration: 'underline',
    },
  });

  const publicationNameStyle = css({
    fontstyle: 'baseMetaM',
  });

  const logoStyle = css({
    borderRadius: '99999px',
    height: '24px',
    width: '24px',
    marginRight: '$x2',
  });

  const titleStyle = css({
    fontstyle: 'baseHeadlineS',
  });

  const subtleMessageStyle = css({
    fontstyle: 'baseHeadlineS',
    color: '$baseNeutral800',
  });

  $: isFrontpage = location === Locations.Home;

  $: teaserListPromise = fetchFrontFeedList({
    trackingKey,
    uuid,
    location: isFrontpage ? 'explore' : 'frontpage',
  });

  $: {
    if ($dirtySelectedSites)
      teaserListPromise = fetchFrontFeedList({
        trackingKey,
        uuid,
        location: isFrontpage ? 'explore' : 'frontpage',
      });
  }

  /* eslint-disable @typescript-eslint/no-empty-function */
  let teasers = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribe: (cb: any) => {
      cb({ data: null, promise: new Promise(() => {}) });
      return { unsubscribe: () => {} };
    },
  } as unknown as ReturnType<typeof createTeaserStore>;
  /* eslint-enable @typescript-eslint/no-empty-function */

  onMount(async () => {
    const teaserList = await teaserListPromise;
    teasers = createTeaserStore({
      teaserList: readable(teaserList.teasers.slice(0, 4)),
    });
  });
</script>

<div class={widgetWrapperStyle()}>
  <Button
    clickLabel="menu"
    clickValue="readinglist"
    type="transparent"
    fullWidth
    size="none"
    href={isFrontpage ? `/?#${Locations.Explore}` : `/?#${Locations.Home}`}
  >
    <span class={headerWrapperStyle()}>
      <span class={headlineStyle()}>
        {isFrontpage ? 'Populært' : 'Mine Aviser'}
      </span>
      <Icon icon="arrow-right" size={24} />
    </span>
  </Button>

  <ul class={teaserListStyle()}>
    {#await $teasers.promise}
      <li>
        <Loading />
      </li>
    {:then}
      {#each $teasers?.data || [] as teaser}
        {#if teaser.renderType === 'internal'}
          <li class={teaserItemStyle()}>
            <a class={linkStyle()} href={teaser.link}>
              <div class={topWrapperStyle()}>
                <div class={publisherStyle()}>
                  {#if teaser.publication}
                    <img
                      class={logoStyle()}
                      src="https://r.acdn.no/local/v3/publications/{teaser.publication}/gfx/square.svg"
                      alt=""
                    />
                  {/if}
                  <span class={publicationNameStyle()}
                    >{removeSoftHyphen(teaser.name || '')}</span
                  >
                </div>
              </div>
              <span class={titleStyle()}>{removeSoftHyphen(teaser.title)}</span>
            </a>
          </li>
        {/if}
      {:else}
        <li class={subtleMessageStyle()}>Her var det rent lite...</li>
      {/each}
    {:catch}
      <li class={subtleMessageStyle()}>
        {$i18n.t('log.error.teasers.fetchFailed')}
      </li>
    {/await}
  </ul>
</div>
