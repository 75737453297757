import { BrickAltTeaser } from '@amedia/brick-alt-teaser';
import '@amedia/brick-toggle';

import Component from './alt-page.component.svelte';

import { persistPWA } from '$utils/local-storage';
import { SvelteElement, defineCustomElements } from '$utils/templates';

const componentName = 'amedia-alt-page';

document.querySelector('amedia-alt-page').innerHTML = '';

export default class AltPage extends SvelteElement {
  constructor(props) {
    super({
      Component,
      options: { ...props, componentName },
    });
  }

  removeOldSelfHostedServiceWorker() {
    console.log('Remove service worker hosted by mozart');
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.active) {
          if (
            registration.active.scriptURL.includes(
              '/api/mozart/v1/firebase-service-worker.js'
            )
          ) {
            registration.unregister();
          }
        }
      });
    });
  }

  async makeFakePasco() {
    if (location.hostname === 'localhost') {
      console.log('mock pasco');
      window.pasco = {
        subscribe: () => {
          return new Promise((resolve) => {
            resolve('fake subscribe');
          });
        },
        unsubscribe: () => {
          return new Promise((resolve) => {
            resolve('fake unsubscribe');
          });
        },
      };
    }
  }

  async connectedCallback() {
    super.connectedCallback();
    this.makeFakePasco();
    persistPWA();
  }
}

defineCustomElements({
  'brick-alt-teaser-v7': BrickAltTeaser,
  [componentName]: AltPage,
});
